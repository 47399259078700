.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-image: url('img/eva-darron-oCdVtGFeDC0-unsplash.jpg');
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.App-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  font-weight: 500;
  color: white;
  padding-top: 15%;
}

.App-footer {
  flex-direction: column;
  font-size: 12px;
  font-weight: 600;
  color: white;
}

.App-link {
  color: #61dafb;
}

.wrap {
  margin: 0px;
  display: flex;
  padding-left: 30px;
  align-content: space-between;
}

.wrap p {
  align-self: flex-start;
}

.logo-container {
  width: 100px;
  align-items: start;
  display: flex;
  padding-top: 30px;
  padding-left: 30px;
}